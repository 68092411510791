<template>
  <div data-main-content="">

    <Header>
      <img slot="logo" style="height:55px;" src="../../assets/images/logo/avatar.png" />
    </Header>

    <div class="slider-wrapper">
      <SliderTwo></SliderTwo>
    </div>

    <div class="slider-wrapper" id="mint-section">
      <div
        class="slide designer-portfolio slider-style-3  d-flex align-center justify-center bg_color--5 rn-slider-height">
        <v-container>
          <v-row style="margin-top:150px;margin-bottom: 150px;">
            <v-col xl="7" lg="7" md="7" cols="12" class="mt_md--40 mt_sm--40 ">
              <div class="inner text-left">
                <span style="font-size:20px;">Get your first ERC404 PFP</span>
              </div>
              <div class="service-btn" style="padding:5px; font-size:25px">
                <div style="margin-top:10px;">1.Avatar use greatly improved ERC404 Protocol</div>
                <div style="margin-top:10px;">2.free mints for 1376 Pandora holders</div>
                <div style="margin-top:10px;">3.free mints for 3624 whitelist members</div>
                <div style="margin-top:10px;">4.Price for 4000 mints is 0.02048 ETH(max mint 5)</div>
                <br />
                <!-- <input v-if="referer && referer!='0x0000000000000000000000000000000000000000'" style="margin-top:10px;font-size:25px; background-color:#eee;" readonly type="text"
                  v-model="referer" placeholder="Referer's address" /> -->
                <div v-if="hasBatch">
                  <label>Mint Number</label>
                  <input style="margin-top:10px;font-size:25px; background-color:#eee;" min="1" max="5" @change="getPrice"
                    type="number" v-model="mintNumber" placeholder="How many want to mint" />
                  <br />
                </div>
                <button style="margin-top:25px;" :disabled="available.length == 0" @click="mintAvatar" type="button"
                  class="v-btn v-btn--is-elevated v-btn--has-bg theme--light elevation-2 v-size--x-large primary">
                  <span class="v-btn__content" style="cursor: pointer;font-size:30px;">MINT {{ mintNumber }} for {{
                    reservePrice }} ETH</span>
                </button>
              </div>
            </v-col>
            <v-col xl="5" lg="5" md="5" cols="12">
              <div class="designer-thumbnail">
                <img src="../../assets/images/preview.gif" alt="Slider Images" />
              </div>
              <div class="inner text-left" style="margin-top:25px;">
                <h1 class="heading-title  ">
                  <span style="font-size:30px;display: block;margin-left:120px;">{{ this.available }}
                    /10000</span>
                </h1>
              </div>
            </v-col>

          </v-row>
        </v-container>
      </div>
    </div>
    <div class="slide  designer-portfolio  slider-style-3   rn-about-area ptb--120 bg_color--1">
      <div class="rn-about-wrapper">
        <v-container>
          <AboutFive :progressValues="progressValues" />
        </v-container>
      </div>
    </div>
    <!-- <div class="rn-service-area interactive-service pb--120 bg_color--1" v-if="myapes.length > 0">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--60">
              <h2 v-if="myapes.length > 1" class="heading-title">My Avatars</h2>
              <h2 v-else class="heading-title">My Avatar</h2>
            </div>
          </v-col>
        </v-row>
        <ServiceThree :avatars="apes" />
      </v-container>
    </div> -->
    <!-- <div class="rn-portfolio-area rn-section-gap bg_color--1" v-if="myapes.length > 0">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 v-if="myapes.length > 1" class="heading-title">My Avatars</h2>
                <h2 v-else class="heading-title">My Avatar</h2>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <div v-for="ape in myapes" :key="ape.name" style="margin:10px 5px;text-align:center;">
              <div style="width:250px; height:250px;text-align: center;">
                <div v-if="ape.image">
                  <img :src="ape.image" style="margin-left:50px;width:150px;" />
                </div>
                <label>{{ ape.name }}</label>
              </div>
            </div>
          </v-row>
        </v-container>
      </div>
    </div> -->
    <div class="rn-gallery-area rn-section-gap bg_color--1 line-separator" v-if="myapes.length > 0">
      <v-container>
        <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 v-if="myapes.length > 1" class="heading-title">My Avatars</h2>
                <h2 v-else class="heading-title">My Avatar</h2>
              </div>
            </v-col>
          </v-row>
        <v-row
          class="gallery-wrapper gallery-grid mt_dec--60"
          id="animated-thumbnials"
        >
          <!-- Start Single Gallery -->
          <a
            class="item-portfolio-static gallery image col-md-3 col-sm-3"
            v-for="(ape, apeIndex) in myapes"            
            :key="apeIndex"
            @click="index = apeIndex"
          >
            <div class="portfolio-static">
              <div class="thumbnail-inner">
                <div class="thumbnail">
                  <img :src="ape.image" alt="Portfolio Images" />
                </div>
              </div>
              <div class="content">
                <div class="inner">
                  <!-- <p>{{ ape.name }}</p> -->
                  <h4>{{ ape.name }}</h4>
                </div>
              </div>
            </div>
          </a>
          <!-- End Single Gallery -->
        </v-row>
        <CoolLightBox
          :items="myapes"
          :index="index"
          :fullScreen="true"
          :effect="'fade'"
          @close="index = null"
        >
        </CoolLightBox>
      </v-container>
    </div>
    <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">Preview of Avatars</h2>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <img src="../../assets/images/preview.png" style="width:100%" />
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="rn-service-area interactive-service pb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--60">
              <h2>Frequently Ask Questions</h2>
              <!-- <p>
                Something shou
                <br />
                but the majority have suffered alteration.
              </p> -->
            </div>
          </v-col>
        </v-row>
        <ServiceThree />
      </v-container>
    </div>
    <!-- <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">Frequently Ask Questions</h2>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <ul>
              <li style="margin-top:10px;">
                <h3>What is Avatar?</h3>
                <p>Avatar is a collection of 10,000 unique NFTs issued by creative and greatly improved ERC404 Protocol
                </p>
              </li>
              <li style="margin-top:10px;">
                <h3>What is ERC404 Protocol?</h3>
                <p>ERC404 is a protocol based on ERC721 and ERC20, but Avatar use self-improved ERC404, you can find the
                  details here:
                  <a target="_blank">https://medium.com/@web8stars/improved-erc404-protocol-88617d31a346</a>
                </p>
              </li>
              <li style="margin-top:10px;">
                <h3>How about the allocation of tokens?</h3>
                <p>50% for free mint/40% for public mint/10% for team</p>
              </li>
              <li style="margin-top:10px;">
                <h3>How to mint Avatar?</h3>
                <p>Connect your wallet and click mint button, then pay the price, whitelist members will be free</p>
              </li>
              <li style="margin-top:10px;">
                <h3>What is the benefit to own Avatar ?</h3>
                <p>We will set a community to give more benefits to holders</p>
              </li>
            </ul>
          </v-row>
        </v-container>
      </div>
    </div> -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import Header from "../../components/meta/Header";
import AboutFive from "../../components/about/AboutFive";
import SliderOne from "../../components/avatar/SliderOne";
import SliderTwo from "../../components/meta/SliderTwo";
import ServiceThree from "../../components/service/ServiceThree";
//import Footer from "../../components/meta/Footer";
import Footer from "../../components/footer/Footer";
import AvatarFactory from "../../utils/contracts/AvatarFactory"
import Erc20 from "../../utils/contracts/erc20"
import { mapGetters, mapState } from "vuex";
import Web3 from "web3";

export default {
  components: {
    Header,
    SliderTwo,
    Footer,
    AboutFive,
    ServiceThree,
  },
  data() {
    return {
      index:null,
      available: 0,
      apes: [
          {
            name: "Web Design",
            title: "T-shirt design is the part of design",
            image: require("../../assets/images/portfolio/big/dp-big--portfolio-01.jpg"),
            src: require("../../assets/images/portfolio/big/dp-big--portfolio-01.jpg"),
          },
          {
            name: "App Development",
            title: "The service provide for designer",
            image: require("../../assets/images/portfolio/big/dp-big--portfolio-02.jpg"),
            src: require("../../assets/images/portfolio/big/dp-big--portfolio-02.jpg"),
          },
          {
            name: "Web Design",
            title: "Mobile App landing Design",
            image: require("../../assets/images/portfolio/big/dp-big--portfolio-03.jpg"),
            src: require("../../assets/images/portfolio/big/dp-big--portfolio-03.jpg"),
          },
          {
            name: "Web Design",
            title: "T-shirt design is the part of design",
            image: require("../../assets/images/portfolio/big/dp-big--portfolio-04.jpg"),
            src: require("../../assets/images/portfolio/big/dp-big--portfolio-04.jpg"),
          },
          {
            name: "Mobile App Development",
            title: "Mobile App landing Design",
            image: require("../../assets/images/portfolio/big/dp-big--portfolio-05.jpg"),
            src: require("../../assets/images/portfolio/big/dp-big--portfolio-05.jpg"),
          },
          {
            name: "Web Design",
            title: "Logo Design creativity",
            thumb: require("../../assets/images/portfolio/big/dp-big--portfolio-06.jpg"),
            src: require("../../assets/images/portfolio/big/dp-big--portfolio-06.jpg"),
          },
        ],
      myapes: [],
      pagenum: 0,
      feeBalance: 0,
      reservePrice: 0.0101,
      referer: '0x0000000000000000000000000000000000000000',
      mintNumber: 1,
      hasBatch: true,
      progressValues: {
        total: 0,
        publicMint: 0,
        airdrop: 0,
        team: 0
      },
    };
  },
  watch: {
    myid() {

    },
  },
  async created() {
    //await this.handleConnect();
    //await this.getPrice()
  },
  mounted() {
    let self = this
    setTimeout(function () {
      self.refreshFeeBalance()
      self.getRest()
      self.getPrice()
    }, 2000)
    //get query string and parse referer parameter
  },
  computed: {
    ...mapGetters(["account", "chainId"]),
    ...mapState(["web3"])
  },
  methods: {

    downloadAndEncodeImage(url) {
      return new Promise((resolve, reject) => {
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader()
            reader.onloadend = () => {
              const base64String = reader.result
              resolve(base64String)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
          .catch(reject)
      })
    },
    async getRest() {
      let contract = new AvatarFactory()
      let minted = await contract.minted()
      let max_id = await contract.maxCount()
      console.log('minted: ', minted, 'max_id: ', max_id)
      //minted = 0;
      this.available = max_id  - minted

      this.progressValues.total = parseInt((this.available / 10000) * 100)
      this.progressValues.publicMint = parseInt((minted <= 4444 ? (Math.ceil((4444 - minted) * 0.9) / 4000) : 0) * 100);
      this.progressValues.airdrop = parseInt((minted > 4444 ? (Math.ceil((10000 - minted) * 0.9) / 5000) : 1) * 100);
      this.progressValues.team = Math.ceil(1000 - minted * 0.1) / 1000 * 100;
    },
    async isReferer() {
      const urlParams = new URLSearchParams(window.location.search);
      const referer = urlParams.get('referer');
      if (referer) {
        let factory = new AvatarFactory()
        let id = await factory.tokenOfOwnerByIndex(referer, 0)
        if (id) {
          this.referer = referer
        }
        else {
          this.refer = ''
        }
      }
    },
    async getPrice() {
      if (this.mintNumber > 100) {
        this.mintNumber = 100
      }
      let contract = new AvatarFactory()
      let price = await contract.getPrice()
      this.reservePrice = price * this.mintNumber
    },
    async refreshFeeBalance() {
      if (!this.web3) return

      this.feeBalance = Web3.utils.fromWei(await this.web3.eth.getBalance(this.web3.account))

      console.log('balance: ', this.web3.account, this.feeBalance)

      let contract = new AvatarFactory()
      let total = await contract.balanceOfNFT(this.web3.account)
      console.log("total", total);
      if (total > 20) {
        total = 20
      }
      for (let i = 0; i < total; i++) {
        const tokenid = await contract.tokenOfOwnerByIndex(this.web3.account, i)
        const json = await contract.tokenUri(tokenid)

        await this.getTokenURI(json, tokenid)
      }
    },
    async getTokenURI(uri, tokenid) {
      let that = this
      fetch(uri)
        .then(resp => resp.json())
        .then(json => {
          console.log("data", json)
          if (uri.indexOf(".json") == -1) {
            json.name = json.name.replace("Avatar #0", "Avatar #" + (tokenid - 1))
            json.src = json.image
          }
          that.myapes.push(json)
        })
        .catch(ex => {
          console.error(ex)
          this.$message.error('获取数据失败，请检查网络连接')
        })
    },

    async mintAvatar() {
      console.log("mint Avatar")
      if (!this.web3) {
        return this.$message.error(this.$t("create.connectWallet"));
      } else if (!this.web3.chainId) {
        return this.$message.error(this.$t("create.connectChain"));
      }
      if (this.feeBalance < this.reservePrice) {
        return this.$message.error(this.$t("avatar.lowBalance"))
      }
      console.log(this.feeBalance)

      let factory = new AvatarFactory()

      let resp = '';
      if (this.mintNumber == 1) {
        resp = await factory.mint(this.reservePrice)
      }
      else {
        resp = await factory.mintBatch(this.reservePrice, this.mintNumber)
      }
      console.log({ resp });
      if (!resp) return
      this.$router.replace({ path: '/meta/createSuccess?txHash=' + resp })
    },
    getDayKey() {
      const d = new Date()
      return 'mintnum_' + d.getMonth() + '-' + d.getDate() + ':', this.web3.account
    },
    getMintNum() {
      const last = localStorage.getItem(this.getDayKey())
      return last ? last : 0
    },
    addMintNum() {
      const last = localStorage.getItem(this.getDayKey())
      const num = last ? parseInt(last) + 1 : 1
      localStorage.setItem(this.getDayKey(), num)
    }
  }
};
</script>
