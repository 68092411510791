<template>
  <v-row class=" row--35" align="center">
    <v-col lg="7" md="7" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <h2 class="heading-title">Mint Progress</h2>
          <p class="description">
            
          </p>
        </div>
        <!-- End .section-title -->

        <v-row class="row mt--30">
          <v-col cols="12">
            <div class="about-us-list">
              <h3 class="heading-title"></h3>
              <div class="progress-wrapper">
                <div class="rn-progress-charts progress-bar--3 custom-color--2 mt--40">
                  <h6 class="heading heading-h6">Total left</h6>
                  <div class="progress">
                    <div class="progress-bar" :style="'width:'+ progressValues.total+'%'">
                      <span v-if="progressValues.total >0" class="percent-label" style="margin-right:10px">{{ progressValues.total }}%</span>
                    </div>
                  </div>
                </div>
                <div class="rn-progress-charts progress-bar--3 custom-color--3 mt--40">
                  <h6 class="heading heading-h6">Public left</h6>
                  <div class="progress">
                    <div class="progress-bar" :style="'width:'+ progressValues.publicMint+'%'">
                      <span  v-if="progressValues.publicMint >0" class="percent-label" style="margin-right:10px">{{ progressValues.publicMint }}%</span>
                    </div>
                  </div>
                </div>
                <div class="rn-progress-charts progress-bar--3 custom-color--1 mt--40">
                  <h6 class="heading heading-h6">Airdrop left</h6>
                  <div class="progress">
                    <div class="progress-bar" :style="'width:'+ progressValues.airdrop+'%'">
                      <span  v-if="progressValues.airdrop >0" class="percent-label" style="margin-right:10px">{{ progressValues.airdrop }}%</span>
                    </div>
                  </div>
                </div>
                <div class="rn-progress-charts progress-bar--3 custom-color--4 mt--40">
                  <h6 class="heading heading-h6">Team left</h6>
                  <div class="progress">
                    <div class="progress-bar" :style="'width:'+ progressValues.team+'%'">
                      <span  v-if="progressValues.team >0" class="percent-label" style="margin-right:10px">{{ progressValues.team }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col lg="5" md="5" cols="12" class="col-lg-5 mt_md--40 mt_sm--40">
      <div class="designer-thumbnail">
        <img src="../../assets/images/logo/avatar.png" alt="Slider Images" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    // 假设每个进度条的值通过一个对象传入，其中包含total, publicMint, airdrop, team等属性
    progressValues: {
      type: Object,
      default: () => ({
        total: 0,
        publicMint: 0,
        airdrop: 0,
        team: 0
      })
    }
  }
};
</script>

<style lang="scss" scoped></style>
