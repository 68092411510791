<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area">
    <!-- Start Single Slide  -->
    <div
      class="slide slide-style-1 slider-fixed--height d-flex align-center bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container class="position-relative">
        <v-row>
          <v-col cols="12">
            <div class="inner" style="height:120px">
              
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
export default {
  components: {
    
  },
};
</script>
