import store from '@/store'
import CodeMonkeyAbi from '../abis/Avatar.json'
import ERC20Abi from '../abis/ERC20.json'
import BaseContract from './base'
import BigNumber from "bignumber.js";
import { message } from 'ant-design-vue';
import Web3 from 'web3';

export const chainConf = {
  534351: {
    contractAddr: '0x4B2652A9Ae387B5c307576Cd7Cf87237e2f99575'
  },
  3666: {
    contractAddr: '0x6753a24b107Bc92af00a1F9995A6A6fbA6b12B73',
  },
  31337:{
    contractAddr: '0x95401dc811bb5740090279Ba06cfA8fcF6113778'
  },
  1: {
    contractAddr: '0x7fba9BB966189Db8C4fE33B7bf67Bfa24203c6AD',
  },
  137: {
    contractAddr: '0x363c5e91B648EBF3Af74Fd9968287564528D27a9',
  }
}
const WJ = '0x7fba9BB966189Db8C4fE33B7bf67Bfa24203c6AD'
const queueAddr = '0x2a044F8bABDC0Ba90A05b479E3eDD7bb29f15013'
// AvatarToken deployed to: 0x29bAf8505f86AB6BAaCAdFC13CD2228B374CA118
// ERC404FactoryProxyAdmin deployed to: 0xE12fA321744b1fDd47759bF06A1d8ACF81eB87E0
// CircleQueue deployed to: 0x4eb664c303F584360829EC2683A53D387C1378fe
// 0xfe17276100000000000000000000000000000000000000000000000000000000000000e0000000000000000000000000000000000000000000000000000000000000012000000000000000000000000000000000000000000000000000000000000000120000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000800000000000000000000000000000000000000000000000000000000000027100000000000000000000000004eb664c303f584360829ec2683a53d387c1378fe000000000000000000000000000000000000000000000000000000000000000b417661746172546f6b656e00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000064176617461720000000000000000000000000000000000000000000000000000
// ERC404FactoryProxy deployed to: 0x363c5e91B648EBF3Af74Fd9968287564528D27a9
export default class AvatarFactory {
  constructor() {
    this.web3 = store.state.web3
    if (!this.web3) {
      return message.error('No web3 connection')
    }
    this.config = chainConf[this.web3.chainId]
    if (!this.config) {
      return message.error('Unsupported chain')
    }
    this.contract = new this.web3.eth.Contract(CodeMonkeyAbi, this.config.contractAddr)
    this.wj = {
      'web3': this.web3,
      'config': {
        'contractAddr': WJ,
      },
      'contract': new this.web3.eth.Contract(ERC20Abi, WJ)
    }
  }

  async ownerOf(tokenid) {
    let owner = await this.contract.methods.ownerOf(tokenid).call();
    return owner
  }

  async balanceOf(owner) {
    return   await this.contract.methods.balanceOf(owner).call()
  }
  async tokenOfOwnerByIndex(owner, index) {
    return await this.contract.methods.tokenOfOwnerByIndex(owner, index).call()
  }

  async balanceOfNFT(owner) {
    return await this.contract.methods.balanceOfNFT(owner).call()
  }
  async tokenUri(tokenid) {
    return await this.contract.methods.tokenURI(tokenid).call()
  }
  async getPrice() {
    return Web3.utils.fromWei(await this.contract.methods.MINT_PRICE().call({ "from": this.web3.account }), "ether")
  }
  async minted(price) {
    return await this.contract.methods.minted().call()
  }
  async maxCount() {
    return await this.contract.methods.maxCount().call()
  }
  async raw(tokenid) {
    let img = await this.contract.methods.raw(tokenid).call()
    return img
  }

  async mint(price) {
    if (price > 0) {
      let value = Web3.utils.toWei(price.toString(), 'ether')
      console.log('price value: ', price, value)
    }
    return await BaseContract.sendTx(this, 'mint', this.web3.account, [], Web3.utils.toWei(price.toString(), 'ether'))
  }
  async mintBatch(price, mintNumber) {
    if (price > 0) {
      let value = Web3.utils.toWei(price.toString(), 'ether')
      console.log('price value: ', price, value)
    }
    return await BaseContract.sendTx(this, 'mintBatch', this.web3.account, [mintNumber], Web3.utils.toWei(price.toString(), 'ether'))
  }
}
