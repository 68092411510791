<template>
    <div class="rn-slider-area interactive-slider">
        <div class="slide slide-style-2 slider-paralax rn-paralax d-flex align-center justify-center bg_image bg_image"
            data-black-overlay="8">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="inner text-left">
                            <span>Avatar - Improved ERC404</span>
                            <h1 class="heading-title">
                                Quantum NFT Liquidity Model Protocal
                            </h1>
                            <div class="slide-btn">
                                <a @click="scrollToSection" class="rn-button-style--2 btn-solid" >Mint Now</a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<script>
export default {
  components: {
    
  },
  methods: {
    scrollToSection() {
      document.getElementById("mint-section").scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

